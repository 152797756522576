<template>
  <div>
    <!--    <iframe-->
    <!--      id="pdfDocument"-->
    <!--      type="application/pdf"-->
    <!--      src="http://82.215.77.158/api/storage/contracts/MjFfMzA3MDE5ODU3ODAwMTE=.pdf"-->
    <!--      width="100%"-->
    <!--      height="700px"-->
    <!--    ></iframe>-->

    <div class="text-center">
      <div id="qr">
        <div id="xxx">
          <span style="font-size:8px; display:block !important">SALOMHAYOT MUDDATLI TO'LOV MARKAZI</span>
          <barcode
            :width="1.7"
            height="53"
            font-size="11"
            :value="barcode_value"
          >
            Show this if the rendering fails.
          </barcode>
          <span style="font-size:8px; display:block !important">MODEL: {{ name }}</span>
        </div>
      </div>
      <b-button
        variant="outline-primary"
        @click="print()"
      >
        Распечатать
        <feather-icon
          icon="PrinterIcon"
          size="12"
        />
      </b-button>
    </div>
  </div>

</template>

<script>
import VueBarcode from 'vue-barcode'

const QRCode = require('qrcode')

export default {
  name: 'Print',
  components: {
    barcode: VueBarcode,
  },
  props: {
    barcode: {
      type: [String, Number],
      default: () => null,
    },
    name: {
      type: [String, Number],
      default: () => null,
    },
  },
  data() {
    return {
      qr: '',
    }
  },
  computed: {
    barcode_value() {
      return this.$route.params.barcode ? this.$route.params.barcode : this.barcode
    },
  },
  mounted() {
    QRCode.toDataURL('07001001', (err, url) => {
      this.qr = url
    })
    this.print()
  },
  methods: {
    print() {
      const prtContent = document.getElementById('xxx')
      const openWindow = window.open('', 'title', 'margin=0')
      openWindow.document.write(prtContent.innerHTML)
      openWindow.document.body.style.display = 'block'
      openWindow.document.body.style.textAlign = 'center'
      openWindow.document.close()
      openWindow.focus()
      openWindow.print()
      openWindow.close()
    },
  },
}
</script>

<style>
#qr {
  margin: 0!important;
  padding: 0!important;
}
/* A4 Landscape*/
@page { size: auto;  margin: 0; }
@media print {
  body{
    align-content: flex-start;
  }
}
</style>
